import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import AuthProtect from 'src/components/Auth/AuthProtect';


// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  layout: DashboardLayout,
  guard: AuthProtect,
  routes: [
    {
      exact: true,
      path: '/',
	  // component: lazy(() => import('src/views/home/LandingPageView'))
	  component: lazy(() => import('src/views/kunosovci/media/VideosView'))
	  
    },
	
    {
      exact: true,
      path: '/test/mysql',
      component: lazy(() => import('src/views/kunosovci/test/MySql'))
    },
    {
      exact: true,
      path: '/test/test',
      component: lazy(() => import('src/views/kunosovci/test/Test'))
    },    
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default HomeRoutes;
