// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  media: '/media',
  test: '/test'
};

export const PATH_AK_APP = {
	media: ROOTS.media,
	videos: path(ROOTS.media, '/videos'),
	videosAdd: path(ROOTS.media, '/videos/add')
};



export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    register: path(ROOTS.auth, '/register'),
    registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify')
  },
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',

};

export const PATH_HOME = {
  components: '/',

};

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: '/',

  },
  

  
};

